:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 130px;
    /* Slick */
    --slick-slider-height: 100vh;
    /* Colors */
    --text-color: #313638;
    --body-bg: #fff;
    --main-color: #101433;
    --accent-color: #4287b2;
    --effect-color: #00467F;
    --header-text-color: #222;
    --header-bg-color: #ddd;
    --footer-bg-color: var(--main-color);
    --footer-text-color: #fff;
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: var(--accent-color);
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 18px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: EBGaramond, sans-serif;
    --alternative-font-family: Arimo, sans-serif;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/baguetteBox.min.css";
@import "/fontmodules/EBGaramond.css";
@import "/fontmodules/Arimo.css";
@import "/cssmodules/magnific-popup.css";
@import "/cssmodules/animate.css";


/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    background: var(--body-bg);
    color: var(--text-color);
}

header {
    top: 0;
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    position: fixed;
    z-index: 101;
    width: 100%;
    height: var(--fixed-header-height);
}

footer {
    background: url('/theme/intendit/graphics/footerbg.jpg'); 
    text-align: center;
    padding: 1rem 0;
    color: var(--footer-text-color);
    padding-top: 100px;
}

header a {
    text-decoration: none;
}

.logo img {
    width: 80%;
}

.logo a {
    padding: 1rem;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

.homepage article {
    padding: 3rem;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

p {
    margin-top: auto;
}

body{
    border-top: 130px transparent solid;
}
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {

}

.menu.menu li {
    margin: 0;

    margin-right: 0.5rem;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    transition: 0.2s ease-in 0s;
    background:#777;
    border-radius: 3px;
    color: #fff;
}

.menu.menu li.active a, .menu.menu li a:hover {
    color: var(--menu-text-hover-color);
    background: var(--effect-color);
    border-radius: 3px;
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick {
    margin-bottom: 0;

}

.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 

.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: calc(100vh - 130px);
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}

.slick-title {
    font-size: 2.3rem;
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0,0,0,.1),2px 2px 6px rgba(0,0,0,.3);
    color: white;
    align-items: center;
    margin: auto;
    justify-content: center;
    background: rgba(225,225,225,0.6);
    width: 70%;
    padding: 2rem;
    min-height: 170px;
}

/*-------------------------------------------------------------
BOXAR
--------------------------------------------------------------*/

/* Scroll
--------------------------------------------------------------*/

.slickholder {
    position: relative;
    overflow: hidden;
}

.scrolldown {
    position:absolute;
    left:0;
    right:0;
    bottom:30px;
    z-index:100;
    width:100%;
    color: #fff;
}

.scrolldown .container {
    text-align:center;
}

.scrolldown .container a {
    display:inline-block;
}

.scrolldown .container a svg.fa-icon {
    height:auto;
    width:6em;  
    fill: #fff;
}

.scrolldown .container a svg.fa-icon:hover {
    fill:#fff;
}

.boxwrapper {
    min-height: 30vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    background: var(--accent-color);
    color: #fff;
}

.homepage .boxwrapper, .tjanster .boxwrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.boxwrapper:nth-of-type(1), .boxwrapper:nth-of-type(3) { 
    background: var(--effect-color);  
}

.boxwrapper:nth-of-type(2) { 
    background: var(--accent-color); 
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption  {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before  {
    content: '';
    transition: transform .3s ease-in 0s;
}


.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}

.boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .3s ease-in 0s;
}

.box-inner {
    padding: var(--padding);
    text-align: center;
}

/*ikoner*/
.fa-icon {height:20px; width: auto; vertical-align:middle !important; }
/*.round {background: rgba(0, 0, 0, 0.2); border-radius: 50%; display: inline-block; height: 80px; width: 80px; margin: 0 10px 10px 0; }*/
.round .fa-icon {fill:#fff; padding: 8px 0; height:80px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
   /* IE fix */ 
    min-height: 1%;
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.contactform {
    background: #181d42;
    margin-bottom: 100px;
        padding: var(--padding);
}

.contactform input, .contactform textarea {
    width: 100%;
    background: #181d42;
    padding: var(--padding);
}

.contactform button {
    background: var(--accent-color);
    color: var(--menu-text-hover-color);
    border: none;
    margin: 0;
    width: 100%;
}

.contactform button:hover {
    background: color(var(--menu-text-bg-hover-color) lightness(65%));
    color: var(--menu-text-hover-color);
}

.contactform .contactform-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}


input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=file], input[type=month], input[type=number], input[type=password], input[type=phone], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], select, textarea {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}

/*------------------------------------------------------------
Intenditfooter
------------------------------------------------------------*/

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
    nav {
        margin-top: -15px !important; 
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
    .homepage article {
        column-count: 1;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
